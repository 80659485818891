import printingFonts from 'core/lib/printingFonts';
import notificationMsg from 'core/utils/notificationMsg';
import ReactDOMServer from 'react-dom/server';
import { ServerStyleSheet } from 'styled-components/macro';
import useCoreMutation from 'core/hooks/mutations/useCoreMutation';
import useSettings from 'hooks/useSettings';
import PRINTER_JOB_CREATE from 'core/graphql/mutations/PRINTER_JOB_CREATE';

const styleSheet = new ServerStyleSheet();

const getHTMlMarkup = (body, styles) => `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <title>Local Shipped</title>
      <style>
        ${printingFonts}

        @media print {
          @page {
              margin: 0;
          }

          body, html {
              margin: 0;
              padding: 0;
          }

            .item-label {
                height: 100vh;
                width: 100vw;
                overflow: hidden;
            }
            .item-label:last-child {
                page-break-after: avoid !important;
            }

            .item-label img {
                width: 100% !important;
            }
        }

        *{
          box-sizing: border-box;
        }

        body,
        html {
          font-family: sora, sans-serif;
        }
      </style>
      ${styles}
  </head>
  <body>
      ${body}
  </body>
  </html>
`;

const typeToggle = (type, overrideType) => overrideType || type;

const paperSizes = {
  default: {
    width: `8.5in`,
    height: `11in`
  },
  ship: {
    width: '4in',
    height: '6in'
  },
  meals2: {
    width: '4.5in',
    height: '3in'
  },
  meals3: {
    width: '2in',
    height: '1.25in'
    // width: '2.5in',
    // height: '2in'
  },
  meals4: {
    width: '2in',
    height: '1.25in'
    // width: '2.5in',
    // height: '2in'
  }
};

function downloadPDF(name, data) {
  const linkSource = `data:application/pdf;base64,${data}`;
  const downloadLink = document.createElement('a');
  const fileName = `${name}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function usePrint() {
  const [settings] = useSettings();
  const [sendToPrintMutation, { loading }] = useCoreMutation(PRINTER_JOB_CREATE);

  /**
   * @param {{content: string; template: string; type: string; id?: string; pages?: number}} data
   * @returns any
   */
  const sendToPrint = async (data) => {
    const { content, type, id = 'default', startPage = 1, pages = 1, template: dataTemplate,printerType, ...other } = data;
    const template = dataTemplate === 'Meals4' ? 'Meals3' : dataTemplate;
    // const template = dataTemplate;
    const overrideType = settings.printingMode;
    const schema = settings.printingSchema || 'default';

    const resultType = typeToggle(type, printerType || overrideType);
    console.log('---print---', template, type, printerType, resultType);
    const res = await sendToPrintMutation({
      content,
      template,
      width: paperSizes[type].width,
      height: paperSizes[type].height,
      type: resultType,
      title: `${id} - ${resultType}`,
      schema,
      pageRanges: `${startPage}-${pages}`,
      ...other
    });

    const resData = res?.data?.printJobPPCreate;

    if (overrideType === 'file' && resData) {
      downloadPDF(`${type}-${id}`, resData.id);
    }

    return resData?.errors;
  };

  return { sendToPrint, loading };
}
